import './App.css';
import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route
} from "react-router-dom";
import { FirestoreProvider, useFirebaseApp } from 'reactfire';
import { getFirestore } from 'firebase/firestore';
import { Box } from '@primer/react'
import {BaseStyles, ThemeProvider} from '@primer/react'

// Pages
// import Home from './pages/Home';
import Trek from './pages/Trek';
import Landing from './pages/Landing';
import Voronoi from './pages/Voronoi';

function App() {
  const app = useFirebaseApp();
  const firestore = getFirestore(app);
  return (
    <FirestoreProvider sdk={firestore}>
      <ThemeProvider>
        <BaseStyles>
          <Box height={'100vh'}>
            {/* <Router>
              <Box height={'64px'}>
              </Box>
              <Box height={'calc(100vh - 96px)'}>
                <Routes>
                  <Route exact path="/" element={<Landing />} />
                  <Route path="/trek" element={<Trek />} />
                </Routes>
              </Box>
            </Router> */}
            <Router>
              <Routes>
                <Route exact path="/" element={<Landing />} />
                <Route path="/trek" element={<Trek />} />
                <Route path="voronoi" element={<Voronoi />} />
              </Routes>
            </Router>
          </Box>
        </BaseStyles>
      </ThemeProvider>
    </FirestoreProvider>
  );
}

export default App;

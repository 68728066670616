import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { FirebaseAppProvider } from 'reactfire';

const firebaseConfig = {
  apiKey: "AIzaSyBNW2dlgOcr5ND7JAxGmOkE_vC3jULjQEo",
  authDomain: "braunch-300aa.firebaseapp.com",
  projectId: "braunch-300aa",
  storageBucket: "braunch-300aa.appspot.com",
  messagingSenderId: "673172523932",
  appId: "1:673172523932:web:d8cd3e5b981d08486bd659",
  measurementId: "G-9QN4GTF2GK"
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <FirebaseAppProvider firebaseConfig={firebaseConfig}>
      <App />
    </FirebaseAppProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

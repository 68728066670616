import React, { useState, useEffect } from 'react';
import '../App.css';

function Landing() {
    const [scrollPosition, setScrollPosition] = useState(0);

    const handleScroll = () => {
        setScrollPosition(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const heroStyle = {
        height: scrollPosition > window.innerHeight * 0.1 ? '0' : `${100 - (scrollPosition / window.innerHeight) * 10}vh`,
        opacity: scrollPosition > window.innerHeight * 0.1 ? 0 : 1 - (scrollPosition / (window.innerHeight * 0.1)),
    };

    const feedStyle = {
        opacity: scrollPosition > window.innerHeight * 0.1 ? 1 : 0,
    };

    const stickyHeaderStyle = {
        opacity: scrollPosition > window.innerHeight * 0.1 ? 1 : 0,
    };

    const downChevronStyle = {
        display: scrollPosition > window.innerHeight * 0.1 ? 'none' : 'block',
    };

    return (
        <body>
            <div className="sticky-header" style={stickyHeaderStyle}>
                <div className="sticky-header-content">
                    <div className="profile-img-container">
                        <img src="profile.png" alt="Profile" className="profile-img" />
                    </div>
                    <div className="profile-info">
                        <div className="profile-name">Jakob Braunschweiger</div>
                        <div className="profile-handle">
                            <span className="social-links">
                                <a href="https://x.com/braunch" target="_blank" rel="noreferrer">
                                    <i className="fa-brands fa-square-x-twitter"></i>
                                </a>
                                <a href="https://github.com/jbraunschweiger" target="_blank" rel="noreferrer">
                                    <i className="fa-brands fa-square-github"></i>
                                </a>
                                <a href="https://linkedin.com/in/jbraunschweiger" target="_blank" rel="noreferrer">
                                    <i className="fab fa-linkedin"></i>
                                </a>
                                <a href="https://youtube.com/@jbraunschweiger" target="_blank" rel="noreferrer">
                                    <i className="fab fa-youtube"></i>
                                </a>
                                <a href="https://tiktok.com/@braunch" target="_blank" rel="noreferrer">
                                    <i className="fab fa-tiktok"></i>
                                </a>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

            <section className="hero" style={heroStyle}>
                <div className="profile">
                    <img src="/profile.png" alt="Profile" className="profile-img" />
                    <div className="profile-name">Jakob Braunschweiger</div>
                    <div className="profile-handle">
                        @braunch
                        <span className="social-links">
                            <a href="https://x.com/braunch" target="_blank" rel="noreferrer">
                                <i className="fa-brands fa-square-x-twitter"></i>
                            </a>
                            <a href="https://tiktok.com/@braunch" target="_blank" rel="noreferrer">
                                <i className="fab fa-tiktok"></i>
                            </a>
                        </span>
                        @jbraunschweiger
                        <span className="social-links">
                            <a href="https://github.com/jbraunschweiger" target="_blank" rel="noreferrer">
                                <i className="fa-brands fa-square-github"></i>
                            </a>
                            <a href="https://linkedin.com/in/jbraunschweiger" target="_blank" rel="noreferrer">
                                <i className="fab fa-linkedin"></i>
                            </a>
                            <a href="https://youtube.com/@jbraunschweiger" target="_blank" rel="noreferrer">
                                <i className="fab fa-youtube"></i>
                            </a>
                        </span>
                    </div>
                    <div className="profile-bio">
                        Trying to understand complex and unpredictable systems.
                        <br />
                        🤖 Product Lead, Microsoft <a href="https://techcommunity.microsoft.com/t5/planner-blog/try-out-the-new-copilot-in-planner-preview-today-in-the-new/ba-p/4193042">Copilot in Planner</a>
                        <br />
                        🤖 Technical Product, Microsoft <a href="https://aka.ms/teamcopilot">Multi-Agent Systems</a>
                        <br />
                        🎙️ Contrarian on the <a href="https://featurecrew.io">Feature Crew Podcast.</a>
                        <br />
                        🌌 Universe designer for <a href="https://x.com/starloggame">Starlog</a>
                        <br />
                    </div>
                </div>
                <div className="down-chevron" style={downChevronStyle}></div>
            </section>

            <section className="feed" style={feedStyle}>
                <div className="post-dummy">
                    <div className="post-content">
                        This is a dummy post, you shouldn't see this in the final version.
                    </div>
                    <div className="post-meta">Posted on August 8, 2024 • None </div>
                </div>

                <div className="post">
                    <div className="post-content">
                        <strong>I'm starting a podcast with my buddies Chris and Dylan!</strong>
                        <br /><br />
                        Our plan is to talk about the latest in tech, AI, and the future of work - and have a few laughs along the way.
                    </div>
                    <div className="post-meta">Posted on August 8, 2024 • Content, AI</div>
                    <iframe
                        className="inline-embed"
                        width="100%"
                        src="https://www.youtube.com/embed/videoseries?si=Boifd3kpou9B5KkX&list=PLYw9orChl2sUFO5Bfin5Xb59eGXFT7IN_"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    ></iframe>
                </div>

                <div className="post">
                    <div className="post-content">
                        Copilot in Planner, the most advanced AI task and work management assistant, is now live in preview within Microsoft Teams!
                    </div>
                    <div className="post-meta">Posted on July 23, 2024 • Work, Microsoft, AI</div>
                    <div className="crosspost" onClick={() => window.open('https://techcommunity.microsoft.com/t5/planner-blog/try-out-the-new-copilot-in-planner-preview-today-in-the-new/ba-p/4193042', '_blank')}>
                        <div className="crosspost-header">
                            <i className="fa-brands fa-microsoft crosspost-icon"></i>
                            <span className="crosspost-source">
                                Microsoft | Copilot in Planner GA
                            </span>
                        </div>
                        <div className="crosspost-content">
                            We are excited to share that Copilot in Planner is now rolled out to 100% of users with a Project Plan 3 or Project Plan 5 license in Microsoft Planner in Teams...
                        </div>
                    </div>
                    <div className="crosspost" onClick={() => window.open('https://techcommunity.microsoft.com/t5/planner-blog/copilot-in-planner-preview-begins-roll-out-to-the-new-microsoft/ba-p/4119094', '_blank')}>
                        <div className="crosspost-header">
                            <i className="fa-brands fa-microsoft crosspost-icon"></i>
                            <span className="crosspost-source">
                                Microsoft | Copilot in Planner launch
                            </span>
                        </div>
                        <div className="crosspost-content">
                            With the power of generative AI, Copilot in Planner streamlines the planning, management, and execution of your work, keeping you informed as you achieve your goals...
                        </div>
                    </div>
                </div>

                <div className="post link-post" onClick={() => window.open('https://gptimes.ai', '_blank')}>
                    <div className="post-image">
                        <img src="blog/gptimes/hero.png" alt="AI concept illustration" />
                    </div>
                    <div className="post-content">
                        Introducing GPTimes, an AI news analysis experiment
                    </div>
                    <div className="post-meta">Posted on January 1, 2024 • Projects, AI</div>
                </div>

                <div className="post">
                    <div className="post-content">
                        <b>Introducing Copilot in Microsoft Planner, your everyday AI planning and project management assistant</b>
                        <br /><br />
                        This AI-powered tool is designed to help teams navigate the complexities of planning and project management, allowing you to focus on achieving your goals with less hassle.
                    </div>
                    <div className="post-meta">Posted on November 15, 2023 • Work, Microsoft, AI</div>
                    <iframe
                        className="inline-embed"
                        src="https://www.youtube.com/embed/C0hmyc3mkVk?si=WI97f3vJi5doEOii"
                        title="YouTube video player"
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                        referrerPolicy="strict-origin-when-cross-origin"
                        allowFullScreen
                    ></iframe>
                </div>

                <div className="post">
                    <div className="post-content">
                        I originally created Practicum to help my quarantine-bound peers get tech experience, and it turned into so much more.
                        <br /><br />
                        Over the course of the summer, almost 100 interns worked with 8 startups to build and ship meaningful products across industries and geos.
                        <br /><br />
                        Practicum was run completely remotely during the pandemic by a scrappy team of six volunteers.
                    </div>
                    <div className="post-meta">Posted on August 24, 2020 • Community</div>
                    <div className="crosspost" onClick={() => window.open('https://www.youtube.com/live/Le6imYEHYjY?si=oIojKH4XrI6SbNMl', '_blank')}>
                        <div className="crosspost-header">
                            <i className="fa-brands fa-youtube crosspost-icon"></i>
                            <span className="crosspost-source">
                                Practicum | Summer Showcase
                            </span>
                        </div>
                        <div className="crosspost-content">
                            After 12 weeks of development, our teams created some incredible products...
                        </div>
                    </div>
                    <div className="crosspost" onClick={() => window.open('https://www.linkedin.com/posts/practicumus_summer2020-startups-interns-activity-6675945521930133504-2gjl?utm_source=share&utm_medium=member_desktop', '_blank')}>
                        <div className="crosspost-header">
                            <i className="fa-brands fa-linkedin crosspost-icon"></i>
                            <span className="crosspost-source">
                                Practicum | Summer Kickoff
                            </span>
                        </div>
                        <div className="crosspost-content">
                            Today we kicked off our Summer 2020 Program! We onboarded our inaugural class of 77 interns, who will work with 8 startups...
                        </div>
                    </div>
                    <div className="crosspost" onClick={() => window.open('https://practicum.us', '_blank')}>
                        <div className="crosspost-header">
                            <i className="fa-solid fa-globe crosspost-icon"></i>
                            <span className="crosspost-source">
                                Practicum | Product Building Community
                            </span>
                        </div>
                        <div className="crosspost-content">
                            Practicum website.
                        </div>
                    </div>
                </div>

                <div className="post">
                    <div className="post-content">
                        I wrote an article about the future of portable computers.
                    </div>
                    <div className="post-meta">Posted on January 7, 2020 • Futurism</div>
                    <div className="crosspost" onClick={() => window.open('https://medium.com/@jakobbraunschweiger/killing-the-keyboard-the-future-of-laptops-290c1948e62d', '_blank')}>
                        <div className="crosspost-header">
                            <i className="fa-brands fa-medium crosspost-icon"></i>
                            <span className="crosspost-source">
                                Jakob's Medium | The Future of Portable Computers
                            </span>
                        </div>
                        <div className="crosspost-content">
                            I have long dreamed about the day that the laptop keyboard would finally die. 2020 looks like the year where it might finally start to happen...
                        </div>
                    </div>
                </div>
            </section>
        </body>
    );
}

export default Landing;
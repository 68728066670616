import { Box, Text, ToggleSwitch, Button } from '@primer/react'
import { RocketIcon } from '@primer/octicons-react'
import { useState, useEffect } from 'react'

function Trek() {
    const [tng, setTng] = useState(true)
    const [ds9, setDs9] = useState(true)
    const [voy, setVoy] = useState(true)
    const [episodes, setEpisodes] = useState([])
    const [loading, setLoading] = useState(true)
    // set index to a random integer between 0 and 305, inclusive
    const [index, setIndex] = useState(0)
    setIndex(Math.floor(Math.random() * 306))

    /*
    make an api call using useEffect and set the episodes state to the response
    url: http://stapi.co/api/v1/rest/episode/search
    */

    useEffect(() => {
        fetchEpisodes();
    }, []);

    const fetchEpisodes = async () => {
        // this is gross
        // const requestOptions = {
        //     method: 'POST',
        //     body: JSON.stringify({ 
        //         seasonNumberFrom: 4,
        //         seasonNumberTo: 7,
        //         usAirDateFrom: "1980-1-1",
        //         usAirDateTo: "2001-12-31"
        //      })
        // };
        const p0 = await fetch(
            "http://stapi.co/api/v1/rest/episode/search?pageSize=100&seasonNumberFrom=4&seasonNumberTo=7&usAirDateFrom=1980-1-1&usAirDateTo=2001-12-31&pageNumber=0",
            {method: 'POST'}
        );
        const p1 = await fetch(
            "http://stapi.co/api/v1/rest/episode/search?pageSize=100&seasonNumberFrom=4&seasonNumberTo=7&usAirDateFrom=1980-1-1&usAirDateTo=2001-12-31&pageNumber=1",
            {method: 'POST'}
        );
        const p2 = await fetch(
            "http://stapi.co/api/v1/rest/episode/search?pageSize=100&seasonNumberFrom=4&seasonNumberTo=7&usAirDateFrom=1980-1-1&usAirDateTo=2001-12-31&pageNumber=2",
            {method: 'POST'}
        );
        const p3 = await fetch(
            "http://stapi.co/api/v1/rest/episode/search?pageSize=100&seasonNumberFrom=4&seasonNumberTo=7&usAirDateFrom=1980-1-1&usAirDateTo=2001-12-31&pageNumber=3",
            {method: 'POST'}
        );
        const response = await Promise.all([p0.json(), p1.json(), p2.json(), p3.json()])
        const data = response[0].episodes.concat(response[1].episodes).concat(response[2].episodes).concat(response[3].episodes)
        //const data = await response.json();
        setEpisodes(data);
        setLoading(false);
        console.log(data);
    };
    
    if(loading) {
        return (
            <h1>Loading...</h1>
        )
    }

    const episode = episodes[index]
    return (
        <Box display={'flex'} width={'100%'} height={'100%'} alignItems={'center'} justifyContent={'center'}>
            <Box width={'400px'} height={'400px'} style={{ padding: '10px' }}>
                <Box display="flex" maxWidth="100%">
                    <Box flexGrow={1} fontSize={2} fontWeight="bold" id="tngLabel">
                        The Next Generation
                    </Box>
                    <ToggleSwitch aria-labelledby="tngLabel" checked={tng} onClick={() => setTng(!tng)} />
                </Box>
                <Box display="flex" maxWidth="100%">
                    <Box flexGrow={1} fontSize={2} fontWeight="bold" id="ds9Label">
                        Deep Space Nine
                    </Box>
                    <ToggleSwitch aria-labelledby="ds9Label" checked={ds9} onClick={() => setDs9(!ds9)} />
                </Box>
                <Box display="flex" maxWidth="100%">
                    <Box flexGrow={1} fontSize={2} fontWeight="bold" id="voyLabel">
                        Voyager
                    </Box>
                    <ToggleSwitch aria-labelledby="voyLabel" checked={voy} onClick={() => setVoy(!voy)} />
                </Box>
                <Button
                    leadingIcon={RocketIcon}
                    size={'large'}
                    variant={'primary'}
                    onClick={() => console.log("Random Episode")}
                    sx={{ width: '100%', marginTop: '10px' }}
                >
                    Random Episode
                </Button>
            </Box>
            <Box width={'400px'} height={'400px'} style={{ padding: '10px' }}>
                <Text fontWeight="bold" fontSize={36}>{episode.title}</Text>
                <br></br>
                <Text fontWeight="bold" fontSize={24}>{episode.season.title}</Text>
                <br></br>
                <Text fontWeight="bold" fontSize={18}>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.</Text>
            </Box>
        </Box>
    )
}

export default Trek;
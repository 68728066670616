import React, { useState, useEffect, useRef, useCallback } from 'react';
import './Voronoi.css';
import { Delaunay } from 'd3';
import { PlusCircle, CircleDotDashed, PaintBucket, Trash2, XCircle, Grid, Sparkles, Save } from 'lucide-react';

const Voronoi = () => {
  const [points, setPoints] = useState([]);
  const [size, setSize] = useState({ width: window.innerWidth, height: window.innerHeight });
  const [tool, setTool] = useState('add');
  const [draggedPointIndex, setDraggedPointIndex] = useState(null);
  const [selectedColor, setSelectedColor] = useState('#FF2600');
  const [cellColors, setCellColors] = useState({});
  const [voronoiCells, setVoronoiCells] = useState([]);
  const [showWelcomeCard, setShowWelcomeCard] = useState(true);
  const [showClearConfirmation, setShowClearConfirmation] = useState(false);
  const canvasRef = useRef(null);
  const defaultColors = ['#FF2600', '#FF9300', '#FFFB00', '#00F900', '#0433FF', '#942192'];

  useEffect(() => {
    const handleKeydown = (e) => {
      switch (e.key) {
        case '1':
          setTool('add');
          break;
        case '2':
          setTool('drag');
          break;
        case '3':
          setTool('paint');
          break;
        case '4':
          setTool('remove');
          break;
        case 'c':
          clearAllPoints();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeydown);

    return () => {
      window.removeEventListener('keydown', handleKeydown);
    };
  }, []);

  // handle window resize
  useEffect(() => {
    const handleResize = () => {
      setSize({ width: window.innerWidth, height: window.innerHeight });
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  useEffect(() => {
    const delaunay = Delaunay.from(points.map((p) => [p.x, p.y]));
    const voronoiDiagram = delaunay.voronoi([0, 0, size.width, size.height]);
    const computedCells = Array.from(voronoiDiagram.cellPolygons());
    setVoronoiCells(computedCells);
  }, [points, size]);

  useEffect(() => {
    drawVoronoi();
    // eslint-disable-next-line 
  }, [voronoiCells, cellColors]);

  const drawVoronoi = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    const ctx = canvas.getContext('2d');
    const { width, height } = size;

    ctx.clearRect(0, 0, width, height);

    voronoiCells.forEach((cell, index) => {
      const color = cellColors[index] || getColorForPoint(index);
      ctx.fillStyle = color;
      ctx.beginPath();
      cell.forEach(([x, y], i) => {
        if (i === 0) {
          ctx.moveTo(x, y);
        } else {
          ctx.lineTo(x, y);
        }
      });
      ctx.closePath();
      ctx.fill();
    });

    ctx.fillStyle = '#000000';
    points.forEach((point) => {
      ctx.beginPath();
      ctx.arc(point.x, point.y, 3, 0, 2 * Math.PI);
      ctx.fill();
    });
  }, [voronoiCells, cellColors, size, points]);

  const getColorForPoint = (index) => {
    const hues = [0, 120, 240, 60, 180, 300];
    const hue = hues[index % hues.length];
    return `hsl(${hue}, 50%, 50%)`;
  };

  const handleCanvasClick = (e) => {
    if (showWelcomeCard) {
      setShowWelcomeCard(false);
      // return here to prevent adding a point when closing the welcome card
    }

    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    if (tool === 'add') {
      setPoints((prevPoints) => [...prevPoints, { x, y }]);
      setCellColors((prevColors) => ({
        ...prevColors,
        [points.length]: selectedColor,
      }));
    } else if (tool === 'remove') {
      const closestPointIndex = findClosestPointIndex(x, y);
      removePoint(closestPointIndex);
    } else if (tool === 'paint') {
      const closestPointIndex = findClosestPointIndex(x, y);
      if (closestPointIndex !== null) {
        setCellColors((prevColors) => ({
          ...prevColors,
          [closestPointIndex]: selectedColor,
        }));
      }
    }
  };

  const handleMouseDown = (e) => {
    if (tool === 'drag') {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const closestPointIndex = findClosestPointIndex(x, y);
      if (closestPointIndex !== null) {
        setDraggedPointIndex(closestPointIndex);
      }
      // set the selected color to the dragged point's color
      setSelectedColor(cellColors[closestPointIndex] || '#000000');
    }
  };

  const handleMouseMove = (e) => {
    if (draggedPointIndex !== null) {
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;

      const updatedPoints = [...points];
      updatedPoints[draggedPointIndex] = { x, y };
      setPoints(updatedPoints);
    }
  };

  const handleMouseUp = () => {
    setDraggedPointIndex(null);
  };

  const findClosestPointIndex = (x, y) => {
    let closestIndex = 0;
    let minDistance = Number.MAX_VALUE;

    points.forEach((point, index) => {
      const distance = Math.sqrt((x - point.x) ** 2 + (y - point.y) ** 2);
      if (distance < minDistance) {
        minDistance = distance;
        closestIndex = index;
      }
    });

    return closestIndex;
  };

  const removePoint = (index) => {
    setPoints((prevPoints) => prevPoints.filter((_, i) => i !== index));
    setCellColors((prevColors) => {
      const newColors = { ...prevColors };
      delete newColors[index];
      return newColors;
    });
  };

  const clearAllPoints = () => {
    setShowClearConfirmation(true);
  };

  const confirmClear = () => {
    setPoints([]);
    setCellColors({});
    setShowWelcomeCard(true);
    setShowClearConfirmation(false);
  };

  const cancelClear = () => {
    setShowClearConfirmation(false);
  };

  const saveAsSVG = () => {
    const svgString = generateSVG();
    const blob = new Blob([svgString], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'voronoi_diagram.svg';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  const generateSVG = () => {
    const { width, height } = size;
    let svgContent = `<svg xmlns="http://www.w3.org/2000/svg" width="${width}" height="${height}">`;

    voronoiCells.forEach((cell, index) => {
      const color = cellColors[index] || getColorForPoint(index);
      const pathData = cell.map((point, i) => `${i === 0 ? 'M' : 'L'}${point[0]},${point[1]}`).join(' ') + 'Z';
      svgContent += `<path d="${pathData}" fill="${color}" />`;
    });

    points.forEach((point) => {
      svgContent += `<circle cx="${point.x}" cy="${point.y}" r="3" fill="black" />`;
    });

    svgContent += '</svg>';
    return svgContent;
  };

  const applyPreset = (type, count) => {
    let newPoints = [];
    let newCellColors = {};
    if (type === 'random') {
      newPoints = Array.from({ length: count }, () => ({
        x: Math.random() * size.width,
        y: Math.random() * size.height
      }));
      newCellColors = newPoints.reduce((acc, _, i) => {
        acc[i] = defaultColors[i % defaultColors.length];
        return acc;
      }
      , {});
    } else if (type === 'grid') {
      const rows = Math.sqrt(count);
      const cols = rows;
      const cellWidth = size.width / cols;
      const cellHeight = size.height / rows;
      for (let i = 0; i < rows; i++) {
        for (let j = 0; j < cols; j++) {
          newPoints.push({
            x: (j + 0.5) * cellWidth,
            y: (i + 0.5) * cellHeight
          });
          newCellColors[newPoints.length - 1] = defaultColors[(i * cols + j) % defaultColors.length];
        }
      }
    }
    setPoints(newPoints);
    setCellColors(newCellColors);
    setShowWelcomeCard(false);
  };

  return (
    <div className="voronoi-container">
      <canvas
        ref={canvasRef}
        width={size.width}
        height={size.height}
        onClick={handleCanvasClick}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
      />

      {showWelcomeCard && (
        <div className="welcome-card">
          <h2>Voronoi Playground</h2>
          <p>Click anywhere on the canvas to add points, or choose a preset:</p>
          <div className="preset-buttons">
            <button onClick={() => applyPreset('random', 20)}>
              <Sparkles size={16} /> 20 Random Points
            </button>
            <button onClick={() => applyPreset('grid', 25)}>
              <Grid size={16} /> 5x5 Grid
            </button>
          </div>
        </div>
      )}

      {showClearConfirmation && (
        <div className="alert-dialog-overlay">
          <div className="alert-dialog-content">
            <h2>Are you sure you want to clear the canvas?</h2>
            <p>This action cannot be undone. All points will be removed.</p>
            <div className="alert-dialog-button">
              <button onClick={cancelClear} className="secondary-button">Cancel</button>
              <button onClick={confirmClear} className="danger-button">Clear</button>
            </div>
          </div>
        </div>
      )}

      <div className="toolbar">
        <button
          className={tool === 'drag' ? 'active' : ''}
          onClick={() => setTool('drag')}
          title="Drag Point (D)"
        >
          <CircleDotDashed />
        </button>
        <button
          className={tool === 'add' ? 'active' : ''}
          onClick={() => setTool('add')}
          title="Add Point (A)"
        >
          <PlusCircle />
        </button>
        <button
          className={tool === 'remove' ? 'active' : ''}
          onClick={() => setTool('remove')}
          title="Remove Point (R)"
        >
          <XCircle />
        </button>
        <button
          className={tool === 'paint' ? 'active' : ''}
          onClick={() => setTool('paint')}
          title="Paint Cell (P)"
        >
          <PaintBucket />
        </button>
        <input
          type="color"
          value={selectedColor}
          onChange={(e) => setSelectedColor(e.target.value)}
          title="Select Color"
          disabled={tool === 'drag' || tool === 'remove'}
        />
        <button onClick={clearAllPoints} title="Clear All (C)" className="clear-button">
          <Trash2 />
        </button>
        <button onClick={saveAsSVG} title="Save as SVG" className="save-button" disabled={points.length < 1}>
          <Save />
        </button>
      </div>
    </div>
  );
};

export default Voronoi;